@import "../bootstrap-extended/include"; // Bootstrap includes
@import "../components/include"; // Components includes
@import "agent_colors-list";
$theme-name: "green-theme";
$primary: setColorFromTheme($theme-name, theme-primary-color);
$theme-primary-color: setColorFromTheme($theme-name, theme-primary-color);
$theme-secondary-color: setColorFromTheme($theme-name, theme-secondary-color);
$btn-bg-color: setColorFromTheme($theme-name, btn-bg-color);
$dropdown-bg-color: setColorFromTheme($theme-name, dropdown-bg-color);
$btn-hover-color: setColorFromTheme($theme-name, btn-hover-color);
$navbar-background-color: setColorFromTheme($theme-name, navbar-background-color);
$light-btn-bg: setColorFromTheme($theme-name, light-btn-bg);
// $nav-pills-bottom-border: setColorFromTheme($theme-name, nav-pills-bottom-border);
$input-border: setColorFromTheme($theme-name, input-border);
.green-layout {
    .horizontal-menu .header-navbar.navbar-horizontal.floating-nav {
        background: $navbar-background-color !important;
    }
    //dropdown.scss
    .dropdown-black>.ng-select-container {
        background: #efefef !important;
        // border-radius: 10px;
    }
    //_palette.scss
    .btn-primary {
        border-color: $theme-primary-color !important;
        background: $btn-bg-color !important;
    }
    .btn-primary:hover:not(.disabled):not(:disabled) {
        background: $btn-hover-color !important;
        box-shadow: 0 8px 25px -8px black !important;
    }
    .btn-outline-danger:hover:not(.disabled):not(:disabled) {
        background: $btn-hover-color !important;
        color: #fff !important;
        border-color: #a8a8a8 !important;
    }
    .btn-outline-dark:hover:not(.disabled):not(:disabled) {
        background: $btn-hover-color !important;
        color: #fff !important;
    }
    .bg-light-primary {
        background: $light-btn-bg !important;
        color: $black !important;
    }
    //form-wizard.scss
    .bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-box {
        background-color: $theme-primary-color !important;
    }
    //styles.scss
    .custom-control-input:checked~.custom-control-label::before {
        color: #fff;
        border-color: $theme-primary-color !important;
        background-color: $theme-primary-color !important;
    }
    //nav.scss
    .nav-pills .nav-link.active {
        border-color: $theme-primary-color !important;
        box-shadow: 0 4px 18px -4px rgb(0 0 0 / 65%) !important;
        background: $theme-primary-color;
    }
    .nav-tabs .nav-link:after {
        background: $btn-bg-color !important;
    }
    .form-control:focus {
        color: #6e6b7b;
        background-color: #fff;
        border-color: $theme-primary-color;
        outline: 0;
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    }
    .ng-select .ng-option.ng-option-marked {
        background-color: rgb(202 252 169 / 19%) !important;
        color: $theme-primary-color !important;
    }
    .ng-select .ng-option.ng-option-selected {
        background-color: $theme-primary-color !important;
        color: #fff !important;
    }
    .ng-select .ng-option.ng-option-selected.ng-option-marked {
        background-color: $theme-primary-color !important;
        color: #fff !important;
    }
    .ng-select.ng-select-focused .ng-select-container {
        border-color: black !important;
    }
    .bs-stepper .bs-stepper-header .step.active .step-trigger .bs-stepper-label .bs-stepper-title {
        color: $theme-primary-color !important;
    }
    .input-group:not(.bootstrap-touchspin):focus-within .form-control,
    .input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
        border-color: $theme-primary-color !important;
    }
    .custom-file-input:focus~.custom-file-label {
        border-color: #000;
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
    }
    .btn-outline-primary {
        border: 1px solid $theme-primary-color !important;
        background-color: transparent;
        color: $theme-primary-color !important;
    }
    .nav-tabs .nav-link.active {
        position: relative;
        color: $theme-primary-color !important;
    }
    .horizontal-menu .header-navbar.navbar-horizontal .dropdown-menu {
        min-width: 155px;
        border: none;
        margin-top: 0;
        background: $theme-primary-color !important;
        min-height: 52px;
    }
    .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li:hover>a {
        background: $theme-primary-color;
        border-radius: 4px;
        color: #fff;
    }
    .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li.active>a {
        font-size: 12px;
        background: $theme-primary-color;
        color: #fff !important;
        border-radius: 4px;
    }
    .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li>.dropdown-menu .active>a {
        background: $theme-secondary-color;
        color: #00CCEE;
        font-weight: 500;
    }
    .verticalal-menu .header-navbar .navbar-vertical ul#main-menu-navigation>li>.dropdown-menu .active>a {
        background: $theme-secondary-color;
        color: #000;
        font-weight: 500;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: $theme-primary-color;
        border-color: #82868b;
    }
    .horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation>li>.dropdown-menu a:hover {
        background-color: transparent;
        color: #dddddd;
    }
    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done,
    .mat-step-header .mat-step-icon-state-edit {
        background-color: $theme-primary-color;
        color: white;
    }
}