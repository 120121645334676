a {
    &:focus {
        outline: none;
    }
}

a:not([href]) {
    color: inherit;
    text-decoration: none;
    @include hover() {
        color: inherit;
        text-decoration: none;
    }
}

body {
    margin: 0;
    font-family: "Montserrat";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #000000;
    text-align: left;
    background-color: #f8f8f8;
    border-color: #000000;
}