// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$vuexy-primary: mat.define-palette(mat.$indigo-palette);
$vuexy-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$vuexy-warn: mat.define-palette(mat.$red-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$vuexy-theme: mat.define-light-theme((color: (primary: $vuexy-primary, accent: $vuexy-accent, warn: $vuexy-warn, )));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($vuexy-theme);
@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import '@core/scss/angular/libs/toastr.component.scss';
@import '@core/scss/base/plugins/forms/form-wizard';
// Chat UI started
@import '@core/scss/base/pages/app-chat.scss';
@import '@core/scss/base/pages/app-chat-list.scss';
@import '@core/scss/base/components/include'; // Components includes
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '@core/scss/base/colors';
@import '@core/scss/base/components/include'; // Components includes
// variable declaration
$ng-select-dark-bg-color: $theme-dark-body-bg;
$ng-select-dropdown-bg-color: $theme-dark-card-bg;
$ng-select-dark-muted-color: $theme-dark-text-muted-color;
$ng-select-dark-border-color: $theme-dark-border-color;
$ng-select-dark-color: $theme-dark-body-color;
$ng-select-dark-input-bg-color: $theme-dark-input-bg;

// ng-select
.ng-select {
    &.ng-select-focused {
        outline: 0;
        box-shadow: $input-focus-box-shadow;

        .ng-select-container {
            border-color: black !important;
            z-index: 2000;
            box-shadow: none !important;
            color: $body-color !important;
            min-height: 38px !important;
        }
    }

    .ng-select-container {
        color: $body-color !important;
        min-height: 38px !important;
    }

    &.error {
        .ng-select-container {
            border-color: $danger !important;
        }
    }

    &.ng-select-multiple {
        .ng-value {
            background-color: $primary !important;
            color: $white;
            border: black !important;
            font-size: 0.8rem !important;
            border-radius: 4px !important;
            display: flex;
            align-items: center;

            .ng-value-icon {
                &.right {
                    border: 0 !important;
                }

                &.left {
                    border: 0 !important;
                }

                &:hover {
                    background-color: transparent !important;
                }
            }

            .ng-value-icon {
                &.left {
                    font-size: 1.1rem !important;
                }

                &.right {
                    font-size: 1.1rem !important;
                }
            }
        }

        .ng-select-container {
            .ng-placeholder {
                top: 8px !important;
            }
        }
    }

    &.ng-select-size-lg {
        .ng-select-container {
            min-height: 48px;
            font-size: 1.2rem !important;

            .ng-value {
                font-size: 1.2rem !important;
                padding: 7px;

                .ng-value-icon {
                    &.left {
                        font-size: 1.1rem !important;
                    }

                    &.right {
                        font-size: 1.1rem !important;
                    }
                }
            }

            .ng-clear-wrapper {
                height: 22px !important;
            }
        }
    }

    &.ng-select-size-sm {
        .ng-select-container {
            min-height: 28px !important;
            font-size: 0.75rem;

            .ng-value {
                padding: 0px;
                font-size: 0.9em !important;

                .ng-value-icon {
                    &.left {
                        font-size: 0.9em !important;
                    }

                    &.right {
                        font-size: 0.9em !important;
                    }
                }
            }
        }
    }

    .ng-option {
        &.ng-option-selected {
            background-color: $primary !important;
            color: $white !important;

            &.ng-option-marked {
                background-color: $primary !important;
                color: $white !important;
            }

            .ng-option-label {
                font-weight: inherit !important;
            }
        }

        &.ng-option-marked {
            background-color: rgba($primary, 0.12) !important;
            color: $primary !important;
        }

        &.ng-option-disabled {
            color: $text-muted !important;
        }
    }

    .ng-arrow {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaBAMAAABbZFH9AAAAG1BMVEUAAACRkZGRkZGSkpKRkZGSkpKSkpKRkZGRkZHLso+9AAAACHRSTlMA+1JoWo0vLFQDmmkAAABlSURBVBjTY6ALSACTbBAOazOYsggAUxEdBkCSuaMVxGGX6BABUo4djQUgrmJHhwFQqkMIrJJJoqOZwaKjUQHIhkg6g6QggEWiQ7Cj0QHIgkpCpaA6wbrgkiAphKSgArJTXRhoBgB9GRPswyvBqAAAAABJRU5ErkJggg==');
        background-size: 12px 12px, 10px 10px;
        background-repeat: no-repeat;
        height: 0.8rem !important;
        padding-right: 1.5rem;
        margin-left: 0;
        margin-top: 0;
        left: 0;
        border-style: none !important;
    }

    &.ng-select-opened>.ng-select-container .ng-arrow {
        top: 0px !important;
    }

    .ng-clear-wrapper {
        height: 18px;
    }
}
.text-blue {
    color: #45579A !important
}

// dark layout style
.dark-layout {
    .ng-select-container {
        background-color: $ng-select-dark-input-bg-color;
        border-color: $ng-select-dark-border-color;
        color: $ng-select-dark-muted-color;

        .ng-placeholder {
            color: $ng-select-dark-muted-color !important;
        }
    }

    .ng-select {
        &.ng-select-multiple {
            .ng-value {
                background-color: rgba($primary, 0.12) !important;
                color: $primary !important;
            }
        }
    }

    .ng-dropdown-header {
        background-color: $ng-select-dark-bg-color;
        border-color: $ng-select-dark-border-color;
    }

    .ng-dropdown-footer {
        background-color: $ng-select-dark-bg-color;
        border-color: $ng-select-dark-border-color;
    }

    .ng-select.ng-select-opened>.ng-select-container {
        background-color: $ng-select-dark-bg-color;
    }

    .ng-option {
        background-color: $ng-select-dropdown-bg-color !important;
        color: $ng-select-dark-color !important;

        &.ng-option-disabled {
            color: $ng-select-dark-muted-color !important;
        }
    }

    ng-dropdown-panel {
        border-color: $ng-select-dark-border-color !important;

        .ng-dropdown-panel-items {
            background-color: $ng-select-dark-bg-color !important;

            .ng-optgroup {
                color: $ng-select-dark-muted-color !important;
            }
        }
    }
}

// Chat Application
.chat-application {

    .chat-sidebar,
    .sidebar-content,
    .chat-user-sidebar,
    .active-user-profile-sidebar,
    .chat-sidebar-wrapper {
        height: 100%;
    }

    .chat-sidebar {
        border-right: 1px solid $border-color;
    }

    .sidebar-content {
        min-width: auto;
    }

    .chat-profile-sidebar {
        top: 0;
        left: 0;
    }

    .sidebar {
        position: unset;
    }
}

// Dark Layout
.dark-layout {
    .chat-application {
        .chat-sidebar {
            background-color: $theme-dark-card-bg !important;
            border-right: 1px solid $theme-dark-border-color;
        }
    }
}

// Chat UI finished
.m-auto {
    margin: auto;
}

.align-m {
    text-align: center;
    height: 240px;
}

.icon-p {
    padding: 20px;
}

.icon-size {
    width: 75px;
    height: 75px;
}

form label {
    font-weight: bold;
}

.border-input {
    color: #000;
}

.mt-120 {
    margin-top: 120px;
}

.avtar-box {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
}

.mb-10 {
    // padding-top: 20px;
    margin-bottom: 30px;
}

.link-b {
    color: blue;
}

div.rel {
    position: relative;
}

.textBlue {
    color: #1d2a62;
}

.blue-background {
    color: #1d2a62;
}

div.abs {
    position: absolute;
    top: 25px;
    margin-left: 44px;
    background-color: black;
    padding: 4%;
    text-align: center;
    right: -15px !important;
    font-size: initial;
    border-radius: 12px;
}

.card-width {
    width: 100%;
}

.link-color {
    color: #3CC9EA;
}

.form-control,
.form-control:focus {
    font-weight: 500;
    color: #000 !important;
}

.box-align {
    height: 135px;
}

.currency-p {
    margin: 10px;
    margin-bottom: 10px !important;
}

.green-text {
    color: green;
}

.card-border-1 {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 10px;
}

.success-text {
    color: green;
}

.mr-10 {
    margin-right: 10px;
}

.mr-20 {
    margin-right: 20px;
}

.mt-12 {
    margin-top: 12px;
}

.w-100 {
    width: 100% !important
}

html,
body {
    height: 100%;
    font-family: 'Montserrat';
}

body {
    margin: 0;
    // font-family: "Montserrat", Helvetica, Arial, serif;
}

.custom-file {
    margin: 0;
    font-family: "Montserrat";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #000000;
    text-align: left;
    border: 1px solid #000000;
    border-radius: 4px;
    background-color: #f8f8f8;
}

input[type=file] {
    color: #000;
}

.custom-file-label {
    line-height: 1.75;
    height: 2.6rem !important;
    color: #000 !important;
}

input::-webkit-input-placeholder {
    color: #000 !important;
}

input::-moz-placeholder {
    color: #000 !important;
}

input:-ms-input-placeholder {
    color: #000 !important;
}

input:-moz-placeholder {
    color: #000 !important;
}

.custom-file-input[disabled] .custom-file-label,
.custom-file-input:disabled .custom-file-label {
    color: #000;
}

.custom-file-label::after {
    color: #000000;
}

.paddingleft0 {
    padding-left: 0px;
}

.bold-text {
    font-family: monospace;
    font-weight: bolder;
}

.fs-30 {
    font-size: 30px;
}

.vcip-alert-box {
    padding: 10px;
    background: #e0f9ff;
    color: #f00;
    font-weight: 500;
    box-shadow: 0 0 0 0 !important;
    font-size: 14px;
    border-radius: 5px;
    border-style: dotted;
    border-color: lightblue;
}

// .mat-stepper-vertical,
// .mat-stepper-horizontal {
// font-family: "Montserrat", Helvetica, Arial, serif !important;
// }
.footerText {
    text-align: center;
    font-size: 10px;
    font-weight: 400;
}


.table-bordered .mob-table {
    display: none;
}



@media (max-width: 767px) {

    .card-order {
        order: 2
    }


}

@media (max-width: 767px) {
    .table-bordered thead {
        display: table-header-group;
    }

    .table-bordered tbody {
        display: block;
        width: 100%;
        overflow-x: auto;
    }

    .table-bordered tr {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .table-bordered .mob-table {
        background-color: #f3f2f7;
    }

    .table-bordered td,
    .table-bordered th {
        width: 50%;
    }

    .table-bordered .table-header {
        display: none;
    }

    .table-bordered .mob-table {
        display: table-cell;
    }


    html body .app-content {
        // padding: calc(0.5rem - 1.9rem + 3rem + 1rem) calc(1rem - 0.3rem) 0 calc(1rem - 0.3rem) !important;
        padding: calc(0.5rem - 1.9rem + 2rem + 1rem) calc(1rem - 1rem) 0 calc(1rem - 1rem) !important; // - 0.8rem to reduce padding in small screen
    }

    .horizontal-layout.navbar-floating .header-navbar-shadow {
        height: 0 !important;
    }

    .btn-bordered {
        background: #fff;
        text-align: center !important;
        border-radius: 5px !important;
        font-size: 11px !important;
        padding: 6px !important;
        border: 1px solid #ababab !important;
        color: #22bcea !important;
    }
}