/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

// @import url('https://fonts.cdnfonts.com/css/montserrat');
.content-body {
    margin-top: 50px !important;
    font-family: 'Montserrat';
}

.pl-25 {
    padding-left: 25px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.mb-5 {
    margin-bottom: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px;
}

.pt-10 {
    padding-top: 10px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.ml5 {
    margin-left: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.ml-15 {
    margin-left: 15px !important;
}

.ml-25 {
    margin-left: 25px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-23 {
    margin-top: 23px;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-45 {
    margin-top: 45px !important;
}

.fs-25 {
    font-size: 25px;
}

.ml5 {
    margin-left: 5px;
}

.ml-10 {
    margin-left: 10px;
}

.mr-10 {
    margin-right: 10px;
}

.pl5 {
    padding-left: 5px;
}

.pl-0 {
    padding-left: 0px !important;
}

.p-10 {
    padding: 10px;
}

.p-15 {
    padding: 15px !important;
}

.pb-15 {
    padding-bottom: 15px !important;
}

.p-20 {
    padding: 20px;
}

.p-40 {
    padding: 40px;
}

.vcip-btn {
    background: #eee !important;
    color: #000 !important;
    font-weight: 600;
    border: 0;
}

.br-5 {
    border-radius: 0.358rem 0px 0px 0.358rem;
}

.b-radius-5 {
    border-radius: 5px !important;
}

.b-radius50 {
    border-radius: 50%;
}

.w-100 {
    width: 100%;
}

.fs16bold {
    font-size: 16px;
    font-weight: 600;
    color: black;
}

.dropdown-bg-black {
    background: #000 !important;
    color: #fff !important;
    width: 100%;
}

.bg-black {
    background: #000;
}

.bg-white {
    background: #fff;
}

.bg-grey {
    background: #5a5a5d;
}

.bg-light-grey {
    background: #f4f4f4;
}

.text-white {
    color: #fff
}

.text-black20 {
    color: #0a0000;
}

.text-black40 {
    color: #838383;
    font-weight: 400;
}

.text-black {
    color: #000
}

.border-right-1 {
    border-right: 1px solid #eee;
}

@media only screen and (max-width: 600px) {
    .content-body {
        margin-top: 0px !important;
    }
    .border-right-1 {
        border-right: 0px solid #eee !important;
    }
}

.content-header-title {
    border-right: 0px solid lighten($gray-600, 10%) !important;
    color: #0c0000 !important;
}

.header-navbar.floating-nav {
    margin-top: -2px !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight: 900 !important;
}

.font-bold-black {
    // font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.font-bold-red {
    // font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 600;
    color: red;
}

.font-normal-black {
    // font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 400;
    color: black;
}

.font-normal-blue {
    // font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 500;
    color: #1866f4;
}

.amout-breakup-bg {
    background: #f4f5f8;
    padding: 10px;
    border-radius: 5px;
}

.content-header-title {
    font-weight: 600 !important;
}

.border-bottom-grey {
    border-bottom: 1px dashed;
}

.border-top-grey {
    border-top: 1px dashed;
}

.border-bottom-white {
    border-bottom: 1px solid #fff !important;
}

.text-muted {
    color: #757575;
}

.progress {
    height: 3px !important;
}

.border-right1 {
    border-right: 1px solid #818181;
}

.fs-7 {
    font-size: 7px;
}

.fs-9 {
    font-size: 9px;
}

@media (min-width: 992px) {
    .fs-7 {
        font-size: 11px !important;
    }
}

@media only screen and (max-width: 600px) {
    .fs-14 {
        font-size: 12px !important;
    }
}

.fs-8 {
    font-size: 8px !important;
}

.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

@media only screen and (min-width: 1000px) {
    .fs-15 {
        font-size: 24px !important;
    }
    .fs-20 {
        font-size: 32px !important;
    }
    .fs-10 {
        font-size: 12px !important;
    }
    .fs-9 {
        font-size: 10px !important;
    }
    .fs-11 {
        font-size: 12px !important;
    }
    .fs-12 {
        font-size: 13px !important;
    }
}

.fs-16 {
    font-size: 16px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px;
}

// .horizontal-menu .navbar.header-navbar .navbar-container {
//     padding: 0px !important;
// }
.middle-text {
    vertical-align: middle;
    display: initial;
}

.link {
    cursor: pointer;
}

.add-relation-btn {
    height: 82px;
    padding: 30px;
    border: 2px dotted #000;
    border-radius: 5px;
    cursor: pointer;
}

.grey-box-padding-30 {
    padding: 30px;
    background: #f6f6f6
}

.grey-box-padding-10 {
    padding: 10px;
    background: #f6f6f6
}

.blue-text {
    color: #1866f4;
    // font-weight: 600;
}

.blue-text-edit {
    color: #1866f4;
    font-size: 14;
    font-weight: 600;
    // font-weight: 600;
}
.green-text-edit {
    color: green;
    font-size: 14;
    font-weight: 600;
    // font-weight: 600;
}

.red-text-delete {
    color: red;
    font-size: 14;
    font-weight: 600;
    // font-weight: 600;
}

.sky-blue-text {
    color: rgb(0, 141, 206) !important
}

.image-self-declaration {
    height: 80%;
    display: block;
    margin: auto;
}

.card-padding {
    padding: 15px 15px 0 15px !important;
}

// .ecommerce-application .grid-view.wishlist-items {
//     grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
// }
.ecommerce-application .grid-view .ecommerce-card .item-img {
    padding-top: 0.5rem rem;
    min-height: 15.85rem;
    display: flex;
    align-items: center;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
    // padding-top: 0.5rem;
    min-height: 3rem !important;
    display: flex;
    align-items: center;
}

@media (max-width: 575.98px) {
    .ecommerce-application .grid-view,
    .ecommerce-application .grid-view.wishlist-items {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 767.98px) {
    .ecommerce-application .grid-view.wishlist-items {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 1199.98px) {
    .ecommerce-application .grid-view.wishlist-items {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

.ecommerce-application .grid-view .ecommerce-card .card-body {
    padding: 6px !important;
}

.ecommerce-card-selected {
    background-color: #c7c7c7;
}

.selectedMenu {
    background-color: #e4f0ff;
}

.dashboard-card {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    margin-bottom: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.add-bank-account {
    width: 9%;
    margin-bottom: 3px;
    margin-top: 0px;
}

.success-gif {
    height: 100px;
    display: block;
    margin: auto;
}

.grey-card {
    background: #f6f6f6;
    border-radius: 5px;
    border: 1px solid #c7c7c7;
}

span.ng-star-inserted {
    margin-right: 3px !important;
}

.rating-img {
    display: block;
    margin: auto;
    width: 100px;
}

span.fa.fa-star-o.ng-star-inserted {
    font-size: 24px;
    margin-top: 15px;
}

.rating .fa.fa-star:before {
    content: "\f005" !important;
    color: #ffb000
}

.rating .fa {
    color: #7d7d7d !important;
}

.sit-back-real-image {
    height: 230px;
    display: block;
    margin: auto;
}

.invoice-title {
    margin-bottom: 1rem;
    text-align: left !important;
}

.invoice-date-wrapper {
    display: block !important;
}

.invoice-total-wrapper {
    width: 100%;
    max-width: 100% !important;
}

.invoice-date {
    margin-left: 0px !important
}

// @media only screen and (max-width: 600px) {
//     .text-right {
//         text-align: left !important;
//     }
// }
.card-padding {
    padding: 20px 15px 0px 15px !important;
}

ngb-accordion>.card.ng-star-inserted {
    margin-bottom: 10px;
}

//loader
.overlay {
    position: fixed;
    top: 0;
    left: 0px;
    z-index: 1100;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: block;
}

.overlay__inner {
    display: block;
    width: 48px;
    height: 48px;
    padding: 4px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -o-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -24px;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.w-h-20 {
    height: 20px;
    width: 20px
}

.w-h-27 {
    height: 27px;
    width: 27px;
}

.more-btn {
    background: #000;
    color: #fff;
    border-radius: 15px;
    display: block;
    margin: auto;
    width: 40%;
    padding: 3px;
    font-size: 13px;
    cursor: pointer;
}

.tooltip-icon {
    outline: none;
    border: none;
    background-color: transparent;
    box-shadow: 0 0 0 0;
}

.box-shadow-none {
    box-shadow: 0 0 0 0 !important;
}

.thead-dark {
    background: #f4f4f4 !important;
    color: #000 !important;
    font-weight: 600 !important;
}

.hr-color {
    border-top: 1px dotted #000000 !important;
}

.green-box {
    background: #e8ffcc;
    padding: 5px;
    border: 1px dotted #1b1b1b;
    border-radius: 5px;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    color: #000;
}

#radio {
    border: 2px solid white;
    box-shadow: 0 0 0 1px rgb(37, 37, 37);
    appearance: none;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    background-color: #fff;
    transition: all ease-in 0.2s;
    vertical-align: middle;
}

#radio:checked {
    background-color: rgb(0, 0, 0);
}

.dashed-border-1 {
    border: 1px dashed #000;
    border-radius: 5px;
    padding: 10px;
}

.border-bottom-dashed {
    border-bottom: 1px dashed #bbbbbb;
}

.rounded-btn-black {
    background: #000;
    color: #fff;
    text-align: center;
    border-radius: 25px;
    font-size: 12px;
    padding: 5px;
}

.rounded-btn-blue {
    background: #22bcea;
    color: #000;
    text-align: center;
    border-radius: 25px;
    font-size: 12px;
    padding: 8px;
    width: 100px;
}

.rounded-btn-bordered {
    background: #fff;
    text-align: center;
    border-radius: 25px;
    font-size: 11px;
    padding: 5px;
    border: 1px solid #ababab;
}

.btn-bordered {
    background: #fff;
    text-align: center;
    border-radius: 5px;
    font-size: 11px;
    padding: 6px;
    border: 1px solid #ababab;
    color: #22bcea !important;
}

.btn-bordered:focus .btn-bordered:hover {
    background: #fff;
    text-align: center;
    border-radius: 5px;
    font-size: 11px;
    padding: 10px;
    border: 1px solid #ababab;
    color: #22bcea !important;
}

.dark-red-text {
    color: #b10606;
}

.black-rounded-btn {
    background: #000;
    color: #fff;
    border-radius: 50px;
    padding: 3px;
}

.bg-light-green {
    background-color: #a0d1a84f;
}

.back-btn {
    cursor: pointer;
    background: #000;
    padding: 10px;
    color: #fff;
    border-radius: 6px;
}

.dashboard-icon {
    width: 40px;
    height: 40px;
    background-size: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 1px rgb(71 66 66 / 8%);
    margin: auto;
    display: block;
    background-repeat: no-repeat;
    background-position: center center;
}

.card-text-bottom {
    font-weight: 500;
    color: #181818;
    font-size: 12px;
}

.h-30 {
    height: 30px;
}

//menu
// @media (max-width: 1199.98px) {
//     .horizontal-layout .header-navbar {
//         background: #fff;
//         // display: none;
//     }
// }
@media screen and (max-width: 992px) {
    .horizontal-layout .header-navbar {
        background: #fff;
        // display: none;
    }
}

@media screen and (min-width: 770px) {
    .horizontal-layout .header-navbar {
        background: #fff;
        display: none;
    }
}

.dropdown-item {
    font-size: 12px;
    color: #fff;
}

.header-navbar .navbar-container ul.navbar-nav li>a.nav-link {
    color: #fff;
    padding: 0 0.5rem;
    position: relative;
    font-size: 12px;
}

.ng-select .ng-select-container {
    background: #fff;
    color: #000;
    border-color: #000;
}

.ng-select .ng-select-container {
    color: #6e6b7b !important;
    min-height: 38px !important;
}

::ng-deep .ng-select.dropdown {
    border: 1px solid black;
}

.ng-select.ng-select-opened .ng-select-container {
    z-index: 1001;
    background: #fff;
    color: #000
}

.ng-select.ng-select-single .ng-select-container .ng-value-container,
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // color: #000;
}

//eye icon and checkmark for input type file
// .input-group-append .input-group-text {
//     border-left: 0;
// }
.input-group-append .input-group-text {
    border-left: 1px solid #cbcbcb;
    margin-right: 5px;
}

// .input-group-text {
//     border: 0px !important;
//     padding: 3px !important;
// }
.dropdown-menu {
    color: #000000 !important
}

.ml-7 {
    margin-left: 8px !important;
}

.bb-0:nth-last-child {
    border-bottom: 0px !important;
}

.btn-gradient {
    background: linear-gradient(118deg, black, rgba(0, 0, 0, 0.7));
    // box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 60%);
}

.border-grey {
    border: 1px solid #e6e6e6;
}

.note-bg {
    background: #fff0d9;
    border-radius: 5px;
    padding: 7px 4px 7px 15px;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
    caret-color: black;
}

.mr-37 {
    margin-right: 37px;
}

.show-section {
    display: initial;
}

@media only screen and (max-width: 600px) {
    .mr-37 {
        margin-right: 5px !important;
    }
    .show-section {
        display: none !important;
    }
}

//OR button on link exisiting card
.btn-action {
    display: block;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    background-color: gray;
    height: 40px;
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important;
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-box-pack: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
    -webkit-box-align: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
    overflow: hidden;
}

.btn-action {
    display: inline-block;
    font-size: 13px;
    line-height: 24px;
    color: #fff;
    padding: 12px 32px;
    // border: 2px solid #C3A165;
    text-transform: uppercase;
    font-weight: 500;
    background-color: transparent;
    border-radius: 0;
    text-decoration: none;
    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
}

.btn-action:before {
    content: '';
    height: 0.7px;
    background: linear-gradient(to right, #bfbfbfd1 44%, #ffffff00 42% 56%, #afafafd1 45% 100%);
    width: 100%;
    position: absolute;
    left: 0px;
    top: 25px;
}

.circular-border {
    border: 1px solid #a5a5a5d1;
    padding: 10px;
    border-radius: 70%;
}

.border-or {
    position: relative;
    width: 100%;
}

.or-text {
    width: 100%;
    text-align: center;
    border-bottom: 1px dashed #7f7f7f;
    line-height: 0.1em;
    margin: 10px 0 20px;
}

.or-text span {
    background: #fff;
    padding: 0 10px;
}

// 
.ng-dropdown-panel {
    z-index: 900 !important;
}

.ng-select.ng-select-opened .ng-select-container {
    z-index: 900 !important;
}

.ng-select.ng-select-focused .ng-select-container {
    z-index: 900 !important;
}

.horizontal-menu .horizontal-menu-wrapper .header-navbar .navbar-container {
    padding: 0 1rem;
    float: right !important;
}

.choose_file {
    position: relative;
    display: inline-block;
    font: normal 14px Myriad Pro, Verdana, Geneva, sans-serif;
    color: #7f7f7f;
    margin-top: 2px;
    background: white
}

.choose_file input[type="file"] {
    -webkit-appearance: none;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
}

// Toast colors
.toast {
    background-color: $white;
    color: $body-color !important;
    .toast-close-button {
        color: $body-color;
        font-size: 1.75rem;
        font-weight: $font-weight-normal;
        top: 0;
        right: 0;
        text-shadow: none;
        &:focus {
            outline: none;
        }
    }
    .toast-title {
        font-size: $font-size-base;
        font-weight: $font-weight-bolder;
        line-height: 1.75;
    }
    .toast-progress {
        opacity: 1;
    }
    &:before {
        content: '';
        height: 24px;
        width: 24px;
        border-radius: 50%;
        color: $white;
        position: absolute;
        left: 1.25rem;
        background-size: $font-size-base;
        background-repeat: no-repeat;
        background-position: center;
    }
    .toast-success {
        .toast-title {
            color: $white;
        }
        .toast-progress {
            background-color: $success;
        }
        &:before {
            background-color: $success;
            background-image: url(str-replace(str-replace($check, 'currentColor', $white), '#', '%23'));
        }
    }
    .toast-error {
        .toast-title {
            color: $white !important;
        }
        .toast-progress {
            background-color: $danger;
        }
        &:before {
            background-color: $danger;
            background-image: url(str-replace(str-replace($remove, 'currentColor', $white), '#', '%23'));
        }
    }
}

.toast-message {
    color: $white !important;
}

//Swal fontsize
.swal2-content {
    font-size: 13px !important;
    color: #000000 !important;
}

.swal2-title {
    font-size: 16px !important;
    margin-bottom: 20px !important;
}

.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
    font-size: 13px !important;
}

.swal2-popup {
    border-radius: 10px !important;
    padding: 40px 20px 20px 20px !important;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #000000;
    background-color: #000000;
}

select:invalid {
    color: gray;
}

option {
    color: black;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::before {
    box-shadow: 0 2px 4px 0 rgb(56 56 56 / 40%) !important;
    border-color: black;
}

.btn-link {
    color: #6e6e6e !important;
}

.form-control,
.form-control:focus {
    font-weight: 500;
    color: #000 !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    font-weight: 500;
}

.circular-btn {
    border-radius: 20px;
    line-height: 0.2;
    font-size: 12px;
    padding: 13px;
}

.w-150 {
    width: 150px;
}

.border-black {
    border-color: #323232;
}

.mr-15 {
    margin-right: 14px;
}

.mr-10 {
    margin-right: 10px;
}

.wh-70 {
    width: 50px;
    height: 50px;
}

.mt-8 {
    margin-top: 8px;
}

.auto-margin {
    display: block;
    margin: auto;
}

.bg-blue {
    background: #1d2a62 !important;
    color: #fff;
}

.redeem-points {
    background: #fef9e9;
    border: 1px solid #aaa;
    border-radius: 10px;
}

//zen desk 
.fOepnc> :first-child {
    padding-bottom: 10px !important;
}

button.wrapper-3hgZT.u-isActionable.u-textLeft.u-inlineBlock.u-borderNone.u-textBold.u-textNoWrap.Arrange.Arrange--middle.u-userLauncherColor {
    display: none !important;
}

.mt25 {
    margin-top: 25px !important;
}

.dark-blue-title {
    color: #1d2a62;
}

.uploaded-icon-bg {
    border: 1px solid #eee !important;
    padding-right: 10px !important;
    height: 40px !important;
}