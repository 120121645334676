@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?1eg3gn');
  src:  url('fonts/icomoon.eot?1eg3gn#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?1eg3gn') format('truetype'),
    url('fonts/icomoon.woff?1eg3gn') format('woff'),
    url('fonts/icomoon.svg?1eg3gn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-eye-solid:before {
  content: "\e901";
}
.icon-eye-slash-solid:before {
  content: "\e903";
}
.icon-bin_delete_dust_erace_garbage_icon:before {
  content: "\e900";
}
.icon-edit_mode_icon:before {
  content: "\e919";
}
.icon-calendar_date_day_event_month_icon:before {
  content: "\e902";
}
.icon-account:before {
  content: "\e905";
}
.icon-bank:before {
  content: "\e906";
}
.icon-credit-card:before {
  content: "\e907";
}
.icon-customer-service:before {
  content: "\e908";
}
.icon-dashboard:before {
  content: "\e909";
}
.icon-google-docs:before {
  content: "\e90a";
}
.icon-growth-graph:before {
  content: "\e90b";
}
.icon-id-card:before {
  content: "\e90c";
}
.icon-information:before {
  content: "\e90d";
}
.icon-more:before {
  content: "\e90e";
}
.icon-placeholder:before {
  content: "\e90f";
}
.icon-power-off:before {
  content: "\e910";
}
.icon-protection:before {
  content: "\e911";
}
.icon-tutorial:before {
  content: "\e912";
}
.icon-users:before {
  content: "\e913";
}
.icon-verified:before {
  content: "\e914";
}
.icon-pencil:before {
  content: "\e915";
}
