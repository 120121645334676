@import '../../bootstrap-extended/include'; // Bootstrap includes
@import '../../components/include'; // Components includes
$header-padding-y: 1.5rem;
$header-padding-x: 1.5rem;
$content-padding-x: 1.5rem;
$content-padding-y: 1.5rem;
$trigger-padding-vertical: 1rem;
$step-box-height: 38px;
$step-box-width: 38px;
.bs-stepper {
    background-color: $white;
    box-shadow: $box-shadow;
    border-radius: 0.5rem;
    .bs-stepper-header {
        padding: $header-padding-y $header-padding-x;
        flex-wrap: wrap;
        border-bottom: 1px solid rgba($black, 0.08);
        margin: 0;
        .line {
            flex: 0;
            min-width: auto;
            min-height: auto;
            background-color: transparent;
            margin: 0;
            padding: 0 1.75rem;
            color: $body-color;
            font-size: 1.5rem;
        }
        .step {
            margin-bottom: .25rem;
            margin-top: .25rem;
            .step-trigger {
                padding: 0;
                flex-wrap: nowrap;
                font-weight: normal;
                .bs-stepper-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    padding: 0.5em 0;
                    font-weight: 500;
                    font-size: 15px;
                    color: rgb(0, 0, 0);
                    background-color: rgba($gray-100, 0.12);
                    border-radius: 0.35rem;
                }
                .bs-stepper-label {
                    text-align: left;
                    margin: 0;
                    margin-top: 0.5rem;
                    margin-right: 1.2rem;
                    margin-left: 1rem;
                    .bs-stepper-title {
                        display: inherit;
                        color: black;
                        font-weight: 600;
                        line-height: 1rem;
                        margin-bottom: 0rem;
                        font-size: 15px;
                    }
                    .bs-stepper-subtitle {
                        font-weight: 500;
                        font-size: 0.85rem;
                        color: black;
                        font-size: 13px;
                    }
                }
                &:hover {
                    background-color: transparent;
                }
            }
            &.active {
                .step-trigger {
                    .bs-stepper-box {
                        background-color: $primary;
                        color: rgb(255, 255, 255);
                        // box-shadow: 0 3px 6px 0 rgba($primary, 0.4);
                    }
                    .bs-stepper-label {
                        .bs-stepper-title {
                            color: $primary;
                        }
                    }
                }
            }
            &.crossed {
                .step-trigger {
                    .bs-stepper-box {
                        background-color: rgb(82, 224, 82) !important;
                        color: black !important;
                    }
                    .bs-stepper-label {
                        .bs-stepper-title {
                            color: $text-muted;
                        }
                    }
                }
                &+.line {
                    color: $primary;
                }
            }
        }
    }
    .bs-stepper-content {
        padding: $content-padding-y $content-padding-x;
        .content {
            margin-left: 0;
            .content-header {
                margin-bottom: 1rem;
            }
        }
    }
    &.vertical {
        .bs-stepper-header {
            border-right: 1px solid $border-color;
            border-bottom: none;
            .step {
                .step-trigger {
                    padding: $trigger-padding-vertical 0;
                }
            }
            .line {
                display: none;
            }
        }
        .bs-stepper-content {
            width: 100%;
            padding-top: 2.5rem;
            .content {
                &:not(.active) {
                    display: none;
                }
            }
        }
        &.wizard-icons {
            .step {
                text-align: center;
            }
        }
    }
    &.wizard-modern {
        background-color: transparent;
        box-shadow: none;
        .bs-stepper-header {
            border: none;
        }
        .bs-stepper-content {
            background-color: $white;
            border-radius: 0.5rem;
            box-shadow: $box-shadow;
        }
    }
}

.horizontal-wizard,
.vertical-wizard,
.modern-horizontal-wizard,
.modern-vertical-wizard {
    margin-bottom: 2.2rem;
}

// Dark Layout
.dark-layout {
    .bs-stepper {
        background-color: $theme-dark-card-bg;
        box-shadow: $theme-dark-box-shadow;
        .bs-stepper-header {
            border-bottom: 1px solid rgba($theme-dark-border-color, 0.08);
            .line {
                color: $theme-dark-body-color;
            }
            .step {
                .step-trigger {
                    .bs-stepper-box {
                        color: $gray-100;
                    }
                    .bs-stepper-label {
                        .bs-stepper-title {
                            color: $theme-dark-body-color;
                        }
                        .bs-stepper-subtitle {
                            color: $theme-dark-text-muted-color;
                        }
                    }
                }
                &.active {
                    .step-trigger {
                        .bs-stepper-box {
                            background-color: $primary;
                            color: $white;
                            box-shadow: 0 3px 6px 0 rgba($primary, 0.4);
                        }
                        .bs-stepper-label {
                            .bs-stepper-title {
                                background-color:  rgb(82, 224, 82) !important; 
                                color: black;
                            }
                        }
                    }
                }
                &.crossed {
                    .step-trigger {
                        .bs-stepper-label,
                        .bs-stepper-title {
                            color: $theme-dark-text-muted-color;
                        }
                    }
                }
            }
        }
        &.vertical {
            .bs-stepper-header {
                border-right-color: $theme-dark-border-color;
            }
        }
        &.wizard-modern {
            background-color: transparent;
            box-shadow: none;
            .bs-stepper-header {
                border: none;
            }
            .bs-stepper-content {
                background-color: $theme-dark-card-bg;
                box-shadow: $theme-dark-box-shadow;
            }
        }
    }
}

// RTl
html[data-textdirection='rtl'] {
    .btn-prev,
    .btn-next {
        display: flex;
        i,
        svg {
            transform: rotate(-180deg);
        }
    }
}

// Media Queries
@media (max-width: 992px) {
    .bs-stepper {
        .bs-stepper-header {
            flex-direction: column;
            align-items: flex-start;
            .step {
                .step-trigger {
                    padding: 0.5rem 0 !important;
                    flex-direction: row;
                }
            }
            .line {
                display: none;
            }
        }
        &.vertical {
            flex-direction: column;
            .bs-stepper-header {
                align-items: flex-start;
            }
            .bs-stepper-content {
                padding-top: 1.5rem;
            }
        }
    }
}